.login-container {
  flex: 1;

  height: 100%;

  background-color: #f1f1f1;
  .title-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 2rem;
    padding: 3rem 1rem 0 1rem;
    > img {
      width: 2rem;
      height: 2rem;
      margin-right: .5rem;
    }
    > span {
      font-size: 1.1rem;
    }
  }
  .login-type-line {
    font-size: 1.4rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 6rem;
    padding: 0 1rem;
  }
  .input-block {
    margin: 1px 0;
    padding: 0 1rem;
  }
  .login-input.am-list-item.am-input-item {
    position: relative;
    z-index: 1;

    padding-left: 0;

    background-color: #f1f1f1;
  }
  .validate-line {
    position: relative;
    z-index: 1;

    padding-right: 4rem;
  }
  .validate-btn-container {
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 4rem;
    height: 100%;
    .validate-btn {
      font-size: .6rem;

      display: flex;
      align-items: center;
      justify-content: center;

      box-sizing: border-box;
      width: 4rem;
      height: 1.3rem;

      border: 1px solid;
      border-radius: .2rem;
      &.sending {
        color: #999;
      }
    }
  }

  .toggle-type-line {
    font-size: .75rem;
    line-height: 1rem;

    height: 1rem;
    padding: 1.5rem .75rem;

    color: #2a52bb;
    > span {
      padding: .5rem 0;
    }
  }
  .agree-line {
    font-size: .7rem;

    position: relative;

    margin: 1rem 0;
    padding: 0 1rem 0 2rem;
    .agree-checkbox {
      position: absolute;
      top: 0;
      left: 1rem;
    }
    .agree-message {
      display: inline-block;
    }
  }
  .btn-line {
    padding: 0 1rem 1rem 1rem;
    > .am-button-disabled {
      opacity: 1;
      color: #bcbcbc;
      background-color: rgb(235, 235, 235);
      &::before {
        border-color: rgb(235, 235, 235);
      }
    }
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;