@import '~antd-mobile/dist/antd-mobile.less';
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f5f5f9;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}
.detail {
  overflow: auto;
  flex: 1;
  width: 100%;
}
.header {
  position: relative;
  z-index: 2;
  width: 100%;
}
.line {
  font-size: .7rem;
  line-height: 1rem;

  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  padding: .3rem .6rem;
}
.line-title {
  display: inline-block;

  width: 5rem;
  padding-right: 1rem;

  color: @text-color-secondary;
}
.line-value {
  display: inline-block;

  color: @heading-color;
}
.case-status {
  font-size: .6rem;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 1rem;
  padding: 0 .2rem;

  color: @neutral-7;
  border: 1px solid @border-color-base;
  border-radius: .2rem;
  background-color: @neutral-2;
}
.order-number {
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 1.5rem;
  height: 1.5rem;
  margin: .25rem 1rem 0 0;

  color: @neutral-7;
  border-radius: .75rem;
  background-color: @neutral-2;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;