@import '~antd-mobile/dist/antd-mobile.less';
.container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 2rem;
  box-sizing: border-box;
}
.btns-line {
  height: 2rem;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  > button {
    border: 0;
    background-color: #fff;
    color: @primary-color;
    height: 2rem;
    padding: 0 1rem;
  }
}
.line-title {
  font-size: .8rem;
  color: #333;
}
.select {
  font-size: 0.7rem;
  line-height: 1rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 1.6rem;
  padding: 0.3rem 0.5rem;
  border: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333;
}
.placeholder {
  color: #999;
}
.inner {
  padding: 1rem;
}
.checkbox {
  position: relative;
  font-size: .8rem;
  color: #333;
  height: 1.6rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1rem;
  vertical-align: middle;
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    border: 0;
    margin: 0;
    padding: 0;
  }
}
.icon {
  display: inline-flex;
  width: 1rem;
  height: 1rem;
  border-radius: .2rem;
  border: 1px solid #ddd;
  margin-right: .4rem;
  justify-content: center;
  align-items: center;
}
.checked {
  color: @primary-color;
}
.indeterminate {
  display: inline-block;
  width: .4rem;
  height: .4rem;
  background-color: @primary-color;
}
.visible-btn {
  border: 0;
  padding: 0 .6rem;
  color: @primary-color;
  font-size: .8rem;
  height: 1.6rem;
  background-color: #fff;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;