@import '~antd-mobile/dist/antd-mobile.less';
.list {
  position: relative;

  background-color: #fff;
}
.list-inner {
  position: relative;
  z-index: 2;

  padding-left: .6rem;
}
.item {
  font-size: .75rem;

  position: relative;

  padding-right: .6rem;
  &:last-child {
    &::after {
      content: none;
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 200%;
    height: 1px;

    content: '';
    transform-origin: top left;

    background-color: @border-color-base;
  }
}
.item-inner {
  font-size: 0;
  line-height: 0;

  padding: .4rem 0;

  text-align: justify;
  &::after {
    display: inline-block;

    width: 100%;
    height: 0;

    content: '';
  }
}
.item-title {
  font-size: .85rem;
  line-height: 1.2rem;

  display: inline-block;

  padding-right: .75rem;

  color: @neutral-6;
}
.item-value {
  font-size: .85rem;
  line-height: 1.2rem;

  text-align: right;

  color: @neutral-8;
}
.item-text {
  display: inline-block;

  text-align: left;
}
.title {
  font-size: .7rem;

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  height: 1.6rem;
  padding: .4rem .6rem 0 .6rem;

  color: #888;
  background-color: @neutral-2;
}
.text-multi {
  font-size: .85rem;
  line-height: 1rem;

  margin-bottom: 1rem;
  padding: .5rem .6rem;

  color: @neutral-8;
  background-color: #fff;
}

.content-item {
  font-size: .75rem;

  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;

  padding-right: .6rem;
  &:last-child {
    &::after {
      content: none;
    }
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 200%;
    height: 1px;

    content: '';
    transform-origin: top left;

    background-color: @border-color-base;
  }
}
.content-item-title {
  line-height: 1.2rem;

  padding: .4rem .75rem .4rem 0;

  color: @neutral-6;
}
.content-item-value {
  line-height: 1.2rem;

  flex: 1;

  padding: .4rem 0;

  text-align: right;

  color: @neutral-8;
}
.files {
  padding: .4rem 0;
}
.file-line {
  position: relative;
  font-size: .7rem;
  padding: .4rem 2rem;
  margin: 0 -.6rem;
  line-height: 1.2rem;
  &.active {
    background-color: #efefef;
  }
}
.file-line-no {
  position: absolute;
  top: .4rem;
  left: 0.6rem;
  width: 1.2rem;
  height: 1.2rem;
  font-size: .6rem;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 1.2rem;
}
.file-line-arrow {
  position: absolute;
  right: .6rem;
  top: .4rem;
  height: 1.2rem;
  width: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
}

/* 2倍屏 */
@media only screen and (-webkit-min-device-pixel-ratio: 2.0) {
  .content-item::after, .item::after {
    -webkit-transform: scale(.5,.5);
            transform: scale(.5,.5);
  }
}
/* 3倍屏 */
@media only screen and (-webkit-min-device-pixel-ratio: 3.0) {
  .content-item::after, .item::after {
    width: 300%;
    height: 1px;

    -webkit-transform: scale(.3333,.3333);
            transform: scale(.3333,.3333);
  }
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;