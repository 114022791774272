@import '~antd-mobile/dist/antd-mobile.less';
.container {
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-color: #f4f5f7;
}
.nav-bar-right {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;

  padding: 10px 0;
  color: @primary-color;
}
.notice {
  padding: 1rem;
  line-height: 1.2rem;
  font-size: .8rem;
  background-color: lighten(@warning-color, 32%);
  color: @warning-color;
}
.send {
  width: 4rem;
  height: 1.8rem;
  margin-top: -.5rem;
  line-height: 1rem;
  font-size: .8rem;
  color: #555;
  background-color: #fff;
  border: 1px solid #eee;
  margin-top: 1px;
  border-radius: .4rem;
  &.active {
    background-color: #efefef;
  }
  &.disabled {
    background-color: #efefef;
    color: #aaa;
  }
}
.line {
  :global {
    .am-input-extra {
      max-width: 5rem;
      max-height: 2rem;
    }
  }
}
.btn {
  padding: 1rem;
}
@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;