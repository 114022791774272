@import '~antd-mobile/dist/antd-mobile.less';
.container {
  height: 100%;
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}
.block {
  margin-bottom: .5rem;
  padding: 0 1.2rem;

  background: #fff;
}
.title {
  padding: 0;
}
.title-text {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 3.2rem;

  color: @heading-color;
}
.week-block {
  &::after {
    display: block;
    clear: both;

    content: '';
  }
}
.week-inner {
  position: relative;
  z-index: 2;

  display: flex;
  align-content: flex-start;
  flex-direction: row;

  padding: .8rem 0;

  justify-items: flex-start;
}
.week-left {
  flex: 2;
}
.week-right {
  flex: 3;
}
.week-left-title {
  font-size: .8rem;
  line-height: 2rem;

  padding-top: .4rem;
}
.week-left-value {
  font-size: 1.8rem;
  line-height: 3.6rem;
}
.week-left-unit {
  font-size: .65rem;

  padding-left: .2rem;
}
.week-right-title {
  font-size: .7rem;
  line-height: 1.2rem;

  color: @neutral-6;
}
.week-right-value {
  font-size: 1.1rem;
  line-height: 1.5rem;

  color: @heading-color;
  + .week-right-title {
    margin-top: .8rem;
  }
}
.week-right-unit {
  padding-right: .4rem;
}
.warning {
  color: @warning-color;
}
.primary {
  color: @primary-color;
}
.year-selector {
  padding: .4rem 0;
}
.year-selector-inner {
  font-size: .9rem;

  display: flex;
  align-items: center;
  flex-direction: row;

  height: 2.4rem;

  color: @heading-color;

  justify-items: flex-start;
}
.year-selector-text {
  margin-right: .2rem;
}
.year-block {
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  justify-items: flex-start;
}
.year-left {
  flex: 1;

  padding-right: .6rem;

  color: @warning-color;
}
.year-right {
  flex: 1;

  padding-left: .6rem;

  color: @primary-color;
}
.year-title {
  font-size: .8rem;
  line-height: 2.4rem;
}
.year-value {
  font-size: 1.4rem;
  line-height: 2.4rem;

  padding-bottom: .4rem;
}
.year-unit {
  font-size: .65rem;

  margin-left: .8rem;
}

@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;