@import '~antd-mobile/dist/antd-mobile.less';
.container {
  box-sizing: border-box;
  height: 100%;

  background-color: #fff;
}
.hide {
  display: none;
}
.sidebar {
  position: relative;
  box-sizing: border-box;
  width: 80vw;
  height: 100vh;
  padding-bottom: 3rem;
  background-color: #fff;
}
.sidebar-inner {
  height: 100%;
  overflow: auto;
  padding: .5rem;
  box-sizing: border-box;
}
.title {
  font-size: .8rem;
  line-height: 1.6rem;

  color: @heading-color;
}
.line {
  margin-bottom: .5rem;
}
.switch-line {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.operate-btns {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  width: 100%;
  height: 3rem;
  padding: .25rem;
}
.btn {
  flex: 1;

  margin: 0 .25rem;
}
.select {
  font-size: 0.7rem;
  line-height: 1rem;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  height: 1.6rem;
  padding: 0.3rem 0.5rem;
  border: 0;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333;
}
.placeholder {
  color: #999;
}
.detail {
  position: fixed;
  z-index: 3;
  bottom: 0;
  left: 0;

  width: 100vw;
  height: 50%;

  background-color: #f4f5f7;
}


@primary-color: #0039BF;@success-color: #34BE12;@error-color: #FF5B4C;@highlight-color: #FF5B4C;@warning-color: #FF9B37;@brand-primary: #0039BF;@brand-primary-tap: #004EEE;@brand-success: #34BE12;@brand-error: #FF5B4C;@brand-warning: #FF9B37;@primary-1: #EEF3FF;@primary-2: #8EB0FF;@primary-3: #5599FF;@primary-4: #457bd9;@primary-5: #004EEE;@primary-7: #002999;@primary-8: #001B73;@primary-9: #000F4D;@primary-10: #000626;@vice-1: #e6feff;@vice-2: #9becf2;@vice-3: #6edae6;@vice-4: #45c5d9;@vice-5: #21afcc;@vice-6: #0099bf;@vice-7: #007599;@vice-8: #005473;@vice-9: #00364d;@vice-10: #001926;@neutral-1: #F9FAFB;@neutral-2: #F4F5F7;@neutral-3: #EEEFF2;@neutral-4: #DCDEE4;@neutral-5: #B7BBC8;@neutral-6: #7E889C;@neutral-7: #5C6478;@neutral-8: #1F2229;@text-color: #5C6478;@text-color-secondary: #7E889C;@heading-color: #1F2229;@item-active-bg: @primary-1;@item-hover-bg: #F9FAFB;@link-color: #3370FF;@background-color-base: #F4F5F7;@disabled-color: #B7BBC8;@border-radius-base: 4px;@tree-node-selected-bg: @primary-1;